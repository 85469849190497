/* src/App.css */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

button {
  font-size: 1rem;
  margin: 0.5rem;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.desktop {
  display: none;
  background-color: white;
  padding: 0 0 1em 0;
}

.smartphone {
  display: block;
  background-color: white;
  padding: 1em 0 1em 0;
}

.contact-us {
  display: none;
}

.is-4rem {
  font-size: 2.7rem !important;
}

.center-embark{
  font-size: 2rem !important;
}

/* for Smartphone */
@media only screen and (max-width: 600px) {
  .buttons {
    justify-content: center !important;
  }

  

  .howWorksPadding {
    padding: 1em;
  }

  #startedSection {
    padding: 1em;
  }

  #howSection {
    margin: 0 2em;
  }

  #morefeaturesSection {
    margin: 0 2em;
  }

  #featuresSection {
    margin: 0;
  }

  .control.is-loading-re::after {
    position: absolute !important;
    right: 6em !important;
    top: -2.7em !important;
    z-index: 4 !important;
    animation: spinAround 1.1s infinite linear !important;
    border: 2px solid #dbdbdb !important;
    border-radius: 9999px !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    content: ":::" !important;
    display: block !important;
    height: 2em !important;
    position: relative !important;
    /* width: 2em !important; */
    margin: 0 auto !important;
    width: 2em !important;
  }
}

/* for Desktop */

@media only screen and (min-width: 600px) {
  .desktop {
    display: block;
    background-color: white;
    padding: 1em 0 1em 0;
  }
  
  .logo-nav-padding{
    padding-right: 3rem;
  }

  .is-4rem {
    font-size: 4rem !important;
  }

  .flip-small {
    padding: "0.5em !important";
  }

  .howWorksPadding {
    padding: 6em 6em 1em 6em;
  }

  .contact-us {
    display: block;
    text-align: -webkit-center;
    font-size: 13px;
    margin: 7px 0;
  }

  .smartphone {
    display: none;
  }

  .balanced-height {
    padding: 0 0 10.5em 0 !important;
  }
}

.phoneTitle {
  font-size: 0.8rem !important;
}

/* for 1408px Desktop */
@media only screen and (min-width: 1408px) {
  .phoneTitle {
    font-size: 2rem !important;
  }
}

.flip-small {
  padding: "0.5em !important";
}

.balanced-height {
  padding: 17em 0 10.5em 0;
}

.custom-container {
  text-align: center;
}
.custom-icons {
  margin-top: 20px;
}

.flip-container {
  border-radius: 12rem;
  perspective: 1000px;
  position: relative;
  /* width: 200px; */
  height: 200px;
  cursor: pointer;
}

.flip-container.flip .flipper {
  transform: rotateY(180deg);
}

.flipper {
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.front,
.back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.back {
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e4872; /* Metallic Blue Background Color for the back side */
  color: #8b9eb7; /* Text Color Matching Metallic Blue for the back side */
}

.more-text {
  color: #1e4872; /* Metallic Blue Color for the "more" text */
  text-decoration: underline;
}

.navbar-menu-open {
  display: block;
}
.formTile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh; /* Adjust the height as needed */
}

.tile-buttons {
  display: flex;
  justify-content: center;
}

.plans {
  text-align: center;
  border: 1px solid white;
  padding: 4px;
  border-radius: 8px;
  color: black;
  background: floralwhite;
}

.control.is-loading-re::after {
  position: absolute !important;
  right: 40em;
  top: -0.7em;
  z-index: 4;
  animation: spinAround 1.1s infinite linear;
  border: 2px solid #dbdbdb;
  border-radius: 9999px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: ":::";
  display: block;
  height: 2em;
  position: relative;
  width: 2em;
}

.faq-item {
  margin-bottom: 10px;
  cursor: pointer;
}

.faq-question {
  font-weight: bold;
  padding: 5px;
  background-color: #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow-icon {
  font-size: 14px;
}

.faq-answer {
  padding: 5px;
  border: 1px solid #ddd;
}

.main-box {
  border: 1px solid black;
  border-radius: 1em;
  padding: 20px;
  position: relative;
  background-color: #fff;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}

.slider-container {
  overflow: hidden;
  margin-top: 20px;
  position: relative;
}

.block-container {
  display: flex;
  transition: transform 0.5s ease;
}

.block {
  border: 1px solid #333;
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
}

.block-30 {
  flex: 30%;
}

.block-70 {
  flex: 70%;
}